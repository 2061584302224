<template>
  <UCard
    :ui="{
      root: 'divide-y-0 ring-0 hover:bg-gray-50',
      header: 'p-0 sm:p-0 h-48 relative',
      body: 'p-0 sm:p-0',
      footer: 'p-6',
    }"
    :as="NuxtLink"
    :to="localePath({ name: 'event-id', params: { id: event.id } })"
    @click.prevent="ionRouter.push(localePath({ name: 'event-id', params: { id: event.id } }))"
  >
    <template #header>
      <NuxtImg
        :src="event.images[0].name"
        provider="upfan"
        :alt="event.name"
        class="size-full rounded-t-lg object-cover brightness-50"
      />
      <div
        v-if="event.season.category.name === 'Music'"
        class="absolute left-4 top-4 size-10 rounded bg-blue-700 p-1 shadow-lg"
      >
        <Icon
          name="i-heroicons-musical-note"
          class="size-full p-1.5 text-white"
        />
      </div>
      <div
        v-else-if="event.season.category.name === 'Sport'"
        class="absolute left-4 top-4 size-10 rounded bg-red-700 p-1 shadow-lg"
      >
        <Icon
          name="i-material-symbols-sports-soccer"
          class="size-full p-1.5 text-white"
        />
      </div>
      <div v-else />
    </template>
    <template #footer>
      <div class="grid grid-cols-5 gap-4">
        <div class="text-center">
          <p class="text-sm font-bold uppercase text-primary-600">
            {{ DateTime.fromISO(event.startsAt).monthShort }}
          </p>
          <p class="text-2xl font-bold">
            {{ DateTime.fromISO(event.startsAt).day }}
          </p>
        </div>
        <div class="col-span-4 text-start">
          <p class="text-sm font-bold h-[2lh] line-clamp-2">
            {{ event.name }}
          </p>
          <p class="mt-2 line-clamp-4 text-sm font-normal text-gray-600">
            {{ event.description }}
          </p>
        </div>
      </div>
      <!---
      <div class="flex items-center justify-between">
        <ULink
          :to="`/events/${event.id}`"
          class="text-primary-400 hover:text-primary-500"
        >
          View details
        </ULink>
        <UBadge
          :to="`/events/${event.id}`"
          size="lg"
        >
          From {{ priceStartsFrom }}$
        </UBadge>
      </div>
      -->
    </template>
  </UCard>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'
import { useRoute } from 'vue-router'
import type { Event } from '~/types'
import { NuxtLink } from '#components'

const props = defineProps<{ event: Event }>()

const localePath = useLocalePath()
const ionRouter = useIonRouter()
const route = useRoute()

const priceStartsFrom = computed(() => {
  return props.event.ticketCategories.reduce((acc, curr) => {
    return Math.min(acc, curr.price)
  }, Infinity)
})
</script>
